import React, { useEffect } from "react"
import { Link } from "gatsby"

import Carousel from "../components/carousel"
import FindDunkin from '../components/findDunkin'
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import iconArrowOver from "../assets/images/icons-logos/icon-arrow-over.svg";
import productCaramelExtraExtra from "../assets/images/product/product scenes/product-family-home-extra extra-caramel.png"
import productExtraExtra from "../assets/images/product/product scenes/product-family-productpage-extra extra.png"
import productOriginal from "../assets/images/product/product scenes/product-family-home-original.png"
import productVanillaExtraExtra from "../assets/images/product/product scenes/product-family-home-extra extra-vanilla.png"
import styles from "./index.module.scss"

const IndexPage = () => {
  useEffect(() => {
    document.querySelector('body').classList.add(styles['hero-img']);
    
    return () => {
      // clean up
      document.querySelector('body').classList.remove(styles['hero-img']);
    }
  }, []);

  return (
    <Layout headerColor='yellow'>
      <SEO title="Home" />
      
      <div className={styles['hero']}>

        <h1>EXTRA<br className={styles['hide-tablet']}></br> EXTRA FOR YOUR FRIDGE.</h1>
        <h3>EXTRA CREAMY, EXTRA SWEET. READY WHEN YOU ARE.</h3>
        <img alt="" className={styles['continue-arrow']} src={iconArrowOver} />

      </div>

      <div className={styles['bravo']} >

        <div className={styles['flex-container']}>
          <div>
            <Link to="/" >
              <img
                alt="Dunkin' Donuts Extra Extra Coffee Creamer"
                className={styles['product-image']}
                src={productExtraExtra}
              />
            </Link>
          </div>

          <div className={styles['extra-extra']}>
            <p className={styles['subhed']}>32 oz &amp; 48 oz</p>
            <h2><span className={styles['text-yellow']}>EXTRA EXTRA</span><br />CREAMER</h2>
            <p className={styles['blurb']}>
              Extra Creamy. Extra Sweet.<br />With Real Cream &amp; Sugar.
            </p>
            <Link className={styles['learn-more-yellow']} to="/" >
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>

      <div className={styles['charlie']}>
        <div className={styles['flex-container']}>
          <div>
            <Link to="/" >
              <img
                alt="Dunkin' Donuts Vanilla Extra Extra Coffee Creamer"
                className={styles['product-image']}
                src={productVanillaExtraExtra}
              />
            </Link>
          </div>

          <div className={styles['extra-extra-van']}>
            <p className={styles['subhed-yellow']}>32 oz</p>
            <h2 className={styles['text-pink']}>
              VANILLA<br />
              <span className={styles['text-yellow']}>EXTRA EXTRA</span><br />
              CREAMER
            </h2>
            <p className={styles['blurb-brown']}>
              Oh, you want extra, extra vanilla?
            </p>
            <Link className={styles['learn-more-pink']} to="/" >
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>

      <div className={styles['delta']}>
        <div className={styles['flex-container']}>
          <div>
            <Link to="/" >
              <img
                alt="Dunkin' Donuts Caramel Extra Extra Coffee Creamer"
                className={styles['product-image']}
                src={productCaramelExtraExtra}
              />
            </Link>
          </div>

          <div className={styles['extra-extra']}>
            <p className={styles['subhed']}>32 oz</p>
            <h2>
              CARAMEL<br />
              <span className={styles['text-pink']}>EXTRA EXTRA</span><br />
              CREAMER
            </h2>
            <p className={styles['blurb-brown']}>
              Or try the Extra Caramel.
            </p>
            <Link className={styles['learn-more-white']} to="/" >
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>

      <div className={styles['echo']}>
        <div className={styles['flex-container']}>
          <div>
            <Link to="/" >
              <img
                alt="Dunkin' Donuts Original Coffee Creamer"
                className={styles['product-image']}
                src={productOriginal}
              />
            </Link>
          </div>

          <div className={styles['extra-extra-original']}>
            <p className={styles['subhed-orange']}>32 oz</p>
            <h2>
              <span className={styles['text-pink']}>ORIGINAL</span><br />
              <span className={styles['text-yellow']}>CREAMER</span>
            </h2>
            <p className={styles['blurb-brown']}>Can't forget the OG.</p>
            <Link className={styles['learn-more-pink']} to="/" >
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>
      
      <Carousel />

      <div className={styles['dunkin-fam']}>
        <h2>MEET THE REST<br className={styles['hide-tablet']}></br> OF THE FAM</h2>
        <a className={styles['corporate-btn']} href="/" >
          MAKE A RUN
        </a>
      </div>

      <FindDunkin />

    </Layout>
  )
}

export default IndexPage
